<template>
    <div class="pt-7 container-fluid">
        <b-card no-body v-if="emails" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="emails" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.name')" prop="name" min-width="250">
                    <template v-slot="{row}">
                        <i :class="icon[row.type]"></i> {{row.name}}
                    </template>
                </el-table-column>

                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">{{$t('general.edit')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
        </b-card>
        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose">
            <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{$t('email.edit_schema')}}</h6>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <div class="mt-4">
                        {{$t('email.usable_tag')}}
                        <ul class="email_tag">
                            <li v-for="tag in modal.data.tag" :key="tag">[%{{tag}}%]</li>
                        </ul>
                    </div>
                    <div class="mb-4">
                        <label class="form-control-label"> ID </label>
                        <b-form-input type="text" :value="modal.data.id" readonly></b-form-input>
                    </div>
                    <b-tabs content-class="mt-3">
                        <b-tab :active="key==0" :title="lang.toUpperCase()" v-for="(lang,key) in setting.lang" :key="key">
                            <base-input type="text" :name="'subject'+lang" :nameAs="lang.toUpperCase()+' - '+$t('email.subject')" :label="$t('email.subject')+'*'" :placeholder="$t('email.subject')" v-model="modal.data.subject[lang]" required>
                            </base-input>
                            <validation-provider :rules="'required'" :name="lang.toUpperCase()+' - '+$t('email.data')" v-slot="{ errors, valid, invalid, validated }">
                                <b-form-group>
                                    <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :name="'data'+lang" v-model="modal.data.data[lang]" :placeholder="$t('email.data')" rows="5" no-resize required></b-form-textarea>
                                    <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </validation-provider>
                        </b-tab>
                    </b-tabs>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { EMAIL_REQUEST, EMAIL_UPDATE, EMAIL_ADD, EMAIL_DELETE, EMAIL_LIST_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;
let emptyModal = {
    status: true,
    tag: [],
    subject: {},
    data: {}
};
export default {
    computed: {
        ...mapGetters({ emails: 'getEmails', setting: 'generalSetting' })
    },
    data() {
        return {
            icon: {
                all: 'fas fa-users text-warning',
                admin: 'fas fa-user-shield text-default',
                doctor: 'fas fa-user-md text-primary',
                patient: 'fas fa-procedures text-success'
            },
            modal: {
                loading: false,
                status: false,
                data: _.cloneDeep(emptyModal)
            },
            loading: false
        };
    },
    methods: {
        handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
            self.modal.data = _.merge(self.modal.data, row);
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        onSubmit() {
            self.onUpdate();
        },
        onUpdate() {
            let id = self.modal.data.id;
            let email = _.pickBy(self.modal.data, (item, key) => {
                return key != 'id';
            });

            self.modal.loading = true;
            self.$store
                .dispatch(EMAIL_UPDATE, { id, email })
                .then(resp => {
                    self.modal.loading = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(EMAIL_REQUEST)
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
